import React, { Component } from 'react';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  InputLabel,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { fromJS } from "immutable";
import StatusList from '../../../components/Status/StatusList.jsx';
import StatusItem from '../../../components/Status/StatusItem.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import _ from 'lodash';
import { Container, Row, Col, Button, Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import JobPreviewDialog from '../../Jobs/Partial/JobPreviewDialog.jsx';
import CustomDialog2 from '../../../components/Dialog/CustomDialog2.jsx';
import Table from '../../../components/Table/Table.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import DropdownMenu from '../../../components/Dropdown/DropdownMenu.jsx';
import HtmlParser from 'react-html-parser';
import { faCircle, faDownload, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker.jsx';
import {RoleAccessService} from '../../../data/role-access'; 
import Filterimg from '../../../assets/img/icons/filter.png';
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import QRCode  from "qrcode.react";
import EVFYLogo from '../../../assets/img/evfy-logo2.png';
import location from '../../../assets/img/Vector.png';
import building from '../../../assets/img/building.png';
import box from '../../../assets/img/box.png';
import comment from '../../../assets/img/comment.png';
import person from '../../../assets/img/person.png';
import time from '../../../assets/img/access_time.png';
import phone from '../../../assets/img/local_phone.png';
import bottomTxt from '../../../assets/img/bottom-txt.png';
import EVFYLogoForLabel from '../../../assets/img/EVFY_Logo.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';
import jsPDF from "jspdf";
import {Verdana} from '../../../components/Fonts/Verdana'
import { Anmollipi } from '../../../components/Fonts/Anmollipi.jsx';
import { InterLight } from '../../../components/Fonts/InterLight.jsx';
import { InterBold } from '../../../components/Fonts/InterBold.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import OrderDetailForm from '../../Dashboard/OrderDetailForm';
import ReactSelect  from 'react-select';

const { 
    REACT_APP_LDS_API_URL,
  } = process.env;

let search_param_global = {};
class OrderHistory extends Component {
  static contextType = AuthContext;
  pages = 1;
  limits = 50;

  constructor(props){
    super(props);
    let rowData = null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,
      user: JSON.parse(localStorage.getItem('user')),
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment(),
        endDate: moment(),
      },
      value : 1,
      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      isLoading: false,
      is_search: false,
      isStatLoading: false,
      openSettingsDialog: false,
      grouping: true,
      state_copy_data: [],
      hiddenColumnSettingId: 0,
      hiddenColumns: [],
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,
      company_logo: '',
      orders_logo: [],
      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : [],
      search_text: '',
      workers: [],
      order_status: null,
      search_do_number: '',
      openDialogMassDelete: false,
      search_worker_id: '',
      search_delivery_number: null,
      search_time_slot: null,
      search_delivery_type: '',
      search_recipient_name: '',
      search_recipient_contact: '',
      search_recipient_email: '',
      search_item_desc: '',
      search_delivery_address: '',
      search_postal_code: '',
      allocation_groups: [],
      search_group_id: '',
      search_status: '',
      order_data: [],
      search_to_time: "",
      search_from_time: "",
      sortColumn: [],
      acKnowLedgeId: null,
      assignToVendorId: null,
      order_status_list: [],
      old_order_status_list: [],
      search_status_id: [],
      uploaders: [],
      oldUploaders: [],
      search_uploader: []
    }
  }
  componentDidMount() {
    // this.callAllocationGroupApi()
    this.loadStatusApi(() => {
      this.callReadApi()
    });
    // this.callOrderReadApi();
  }

  callInitApis = () => {
    this.loadStatusApi(()=> {
        this.callReadApi();
    });
  }
  
  setColumnSetting = () => {
    const { accessToken } = this.context;
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data ? columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",") : [];
      columnHiddenSettingsId = columnHiddenSettings && columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    
    this.setState({
      hiddenColumns: hiddenColumns,
      columnHiddenSettingsId: columnHiddenSettingsId,
    },() => {
      const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
      let originalColumn = this.getColumns(false).filter((obj) => obj.hidden == false);
      let columnOrder = jobColumnOrder ? JSON.parse(jobColumnOrder.json_data) : []

      let foundHideColumn = false
      let searchColumns = this.getColumns(false);
      this.state.hiddenColumns.map((val) => {
        if(val != '' && !searchColumns.filter((obj) => obj.field == val).length > 0) {
          foundHideColumn = true;   
        }
      });
      if(columnOrder.length !== originalColumn.length || foundHideColumn) {
        originalColumn = this.getColumns(false);
        columnOrder = Array.from(Array(originalColumn.length).keys()); 
        let findIndex = originalColumn.map((obj,index) => obj.hidden == true ? index : '' ).filter(String);

        if(findIndex.length > 0) {
          let removeIndex;
          findIndex.map((num) => {
            removeIndex = columnOrder.findIndex((id) => id == num);
            columnOrder.splice(removeIndex,1); 
          });              
        }
        
        api.create('application_company_settings', {
          enabled: true,
          rule: "job_column_settings",
          json_data: JSON.stringify([''])
        }, accessToken)
          .then(result => {
            if (result.data.data.result.id) {
              localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
            }
        });
        this.callCreateColumnOrder(columnOrder);
      }

      this.setState({
        sortColumn: columnOrder
      });          
    });   
    
  }


  callOrderReadApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        sort: "latest"
      };
      
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              order_data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });

            // this.loadStatusApi();
          }, (error, type) => {
            apiUtil.toast(error,  'warning', 'error');
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  loadStatusApi = (callback = null) => {
    this.setState({
      isLoading: true
    }, () => {
      this.callStatusApi((data, total) => {
        if(data && data.length > 0){
          let status = [];
          let ackTemp = {}
          let AckTep = {}
          let AssignToVendorTemp = {}
          data.map((item, i) => {
            if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
              return status.splice(1, 0, {
                order: 8,
                id: item.status_details.id,
                status: "On Hold",
                text: "On Hold",
                total: item.total_job,
              });
              // status[0].push(objectVal)
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
              return status.splice(2, 0, {
                order: 1,
                id: item.status_details.id,
                status: "Pending",
                text: "Pending",
                total: item.total_job,
              });
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
              return status.splice(3, 0, {
                order: 3,
                id: item.status_details.id,
                status: item.status_details.status,
                text: item.status_details.status,
                total: item.total_job,
              });
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
              this.setState({
               acKnowLedgeId: item.status_details.id
              });
              ackTemp = item
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
              return status.splice(5, 0, {
                order: 5,
                id: item.status_details.id,
                status: item.status_details.status,
                text: item.status_details.status,
                total: item.total_job,
              });
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
              return status.splice(6, 0, {
                order: 6,
                id: item.status_details.id,
                status: item.status_details.status,
                text: item.status_details.status,
                total: item.total_job,
              });
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
              return status.splice(7, 0, {
                order: 7,
                id: item.status_details.id,
                status: item.status_details.status,
                text: item.status_details.status,
                total: item.total_job,
              });
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
              return status.splice(8, 0, {
                order: 9,
                id: item.status_details.id,
                status: item.status_details.status,
                text: item.status_details.status,
                total: item.total_job,
              });
            } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
              return status.splice(9, 0, {
                order: 10,
                id: item.status_details.id,
                status: item.status_details.status,
                text: item.status_details.status,
                total: item.total_job,
              });
            } else if(item.status_details.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor") {
              // return status.splice(10, 0, {
              //   order: 2,
              //   id: item.status_details.id,
              //   status: item.status_details.status,
              //   text: item.status_details.status,
              //   total: item.total_job,
              // });
              this.setState({
                assignToVendorId: item.status_details.id
              });
              AssignToVendorTemp = item
            }
          });
      
          status = status.sort(function(a, b) {
            return (a.order - b.order);
          });
          let tableStatus = status;
          
          let search_order_status = [];
          status.map((obj) => {
            search_order_status.push({
              value: obj.id,
              label: obj.text
            });              
          });
        
          status.unshift({
            id: 0,
            status: 'Total Orders',
            total: total,
          });
          
          if( Object.keys(ackTemp).length !== 0 ) {
            status.forEach(st=>{
              if(st.status == "In Progress") {
                st.total = st.total + ackTemp.total_job
              }
            })
          }
      
          if( Object.keys(AssignToVendorTemp).length !== 0 ) {
            status.forEach(st=>{
              if(st.status == "Assigned") {
                st.total = st.total + AssignToVendorTemp.total_job
              }
            })
          }

          this.setState({
            status: status,
            order_status_list: search_order_status,
            old_order_status_list: search_order_status,
            status_total: total,
            tableStatus: tableStatus,
          }, () => {
            if(callback) {
              callback();
            }
          });
        } else {
          if(callback) {
            callback();
          }
        }
      });
    });
  }

  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }

  callMassDeleteApi = ( callback = null) => {
    const { accessToken } = this.context;

      let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "pending");
      let param = {
        destroy_all: true,
      };
      let selectedRow = this.rowData;
      let ids = '';
      let own_order = '';
      if(selectedRow != null && selectedRow != '') {
        let not_pending_order = selectedRow.filter((item) => item.order_status_id != orderStatusItem.id)
        if (not_pending_order.length > 0) {
          apiUtil.toast('Only Pending order(s) can be deleted', 'warning', 'error');
          this.setState({
            openDialogMassDelete: false,
          })
          return
        } else {
          selectedRow.map((item, i) => { ids += item.id +','; });
        }
      } else {
        apiUtil.toast('You must select order!', 'warning', 'error');
        this.setState({
          openDialogMassDelete: false,
        })
        return
      }

      ids = ids.slice(0, -1);
      this.setState({
        isLoading: true
        }, () => {
      ldsApi.delete('delete/orders/multiple?id=' + ids, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {

            this.loadStatusApi();
            this.callReadApi();            
            apiUtil.toast('Successfully Deleted', 'check_circle');
            this.rowData = null
            this.setState({
              openDialogMassDelete: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            apiUtil.toast(
              error,
              "check_circle"
            );
            this.setState({
              openDialogMassDelete: false,
            })
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
      });
     


  }

  massDeleteDialog = () => {
    
    return <CustomDialog 
      open={this.state.openDialogMassDelete}
      title={'Delete'}
      onClose={() => {
        this.rowData = '';
        this.setState({
          openDialogMassDelete: false,
        });
      }}
      onOk={() => {
        this.callMassDeleteApi(() => {
          this.setState({
            openDialogMassDelete: false,
          }, () => {
            this.rowData = '';
          });
        });
      }}
    >
      <Box>Are you sure you want to delete selected items?</Box>
    </CustomDialog>
  }

  callAllocationGroupApi = (callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let param = {
        sort: "latest",
      };
      
      // if(order)
      ldsApi.read('allocation_groups', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];
  
          this.setState({
            allocation_groups: result,
            isLoading: false,
          })
        }, (error, type) => {
          if(callback){
            callback([]);
            this.setState({
              isLoading: false,
            })
          }
        });
      });
    });
  }

  callReadApi = (search = '', do_num = '', uploader = '') => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      this.rowData = null;
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let searchAdv = false;
      if( 
          this.state.search_status_id.length > 0 ||
          this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_uploader.length > 0 )
      {
        searchAdv = true;
      }
      let param = {
        page: this.pages,
        take: this.limits,
        search: this.state.search_text,
        adv_search: searchAdv,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status || (apiUtil.customFilter(this.state.search_status_id, 'label', 'In Progress')? this.state.acKnowLedgeId + ','+ this.state.search_status_id.map(obj => obj.value).join(',') : this.state.search_status_id.map(obj => obj.value).join(',')),
        do_number: !this.state.search_do_number ? this.state.search_do_number : this.state.search_do_number.trim(),
        worker_ids: !this.state.search_worker_id ? this.state.search_worker_id : this.state.search_worker_id.trim(),
        vendor_ids: !this.state.search_vendor_id ? this.state.search_vendor_id : this.state.search_vendor_id.trim(),
        order_number: !this.state.search_delivery_number ? this.state.search_delivery_number : this.state.search_delivery_number.trim(),
        time_slot: (this.state.search_from_time && this.state.search_to_time) ? (this.state.search_from_time + " - " + this.state.search_to_time) : '',
        delivery_type: !this.state.search_delivery_type ? this.state.search_delivery_type : this.state.search_delivery_type.trim(),
        recipient_name: !this.state.search_recipient_name ? this.state.search_recipient_name : this.state.search_recipient_name.trim(),
        recipient_contact: !this.state.search_recipient_contact ? this.state.search_recipient_contact : this.state.search_recipient_contact.trim(),
        recipient_email: !this.state.search_recipient_email ? this.state.search_recipient_email : this.state.search_recipient_email.trim(),
        item_description: !this.state.search_item_desc ? this.state.search_item_desc : this.state.search_item_desc.trim(),
        delivery_address: !this.state.search_delivery_address ? this.state.search_delivery_address : this.state.search_delivery_address.trim(),
        postal_code: !this.state.search_postal_code ? this.state.search_postal_code : this.state.search_postal_code.trim(),
        uploader: this.state.search_uploader.map(obj => obj.id).join(','),
        allocation_group: !this.state.search_group_id ? this.state.search_group_id : this.state.search_group_id.trim(),
        sort: "latest"
      };

      if(this.state.order_status == null && apiUtil.customFilter(this.state.search_status_id, 'label', 'Assigned')) {
        param.order_status += ','+this.state.assignToVendorId;
      }
      search_param_global = param ;
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
          this.setColumnSetting(); 
            let sortJob = [];
            // if(data.result && data.result.length > 0) {
            //   let colList = data.result.filter( c => c.collection_reference == null);
            //   if(colList.length > 0) {
            //     colList.map(cjob => {
            //       data.result.map(d => {
            //         if(d.collection_reference == cjob.order_number) {
            //           sortJob.push(d);
            //         }
            //       })
            //       sortJob.push(cjob);
            //     });
            //     data.result.map((obj) => {
            //       if(!sortJob.filter((s) => s.order_number == obj.order_number).length > 0) {
            //        sortJob.push(obj);
            //       }
            //     });
            //   } else {
            //     sortJob = data.result;   
            //   }
            // }
            this.setState({
              data: data.result,
              state_copy_data: _.cloneDeep(data.result),
              uploaders: (data.uploaders && data.uploaders.length > 0 && searchAdv == false) ? data.uploaders : this.state.uploaders,
              company_logo : data.company_logo_base_64? data.company_logo_base_64 : '',
              orders_logo : data.orders_image_base64.length > 0? data.orders_image_base64 : [],
              total: data.total,
              isLoading: false,
            }, () => {
              this.setState({
                oldUploaders: this.state.uploaders
              });                  
            });

            // this.loadStatusApi();
          }, (error, type) => {
            apiUtil.toast(error,  'warning', 'error');
            this.setState({
              data: [],
              total: 0,
              uploaders:[],
              isLoading: false,
            });
          });
      });
    });
  }

  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      
      let selectedRow = this.rowData;
      let ids = '';
      if(selectedRow != null && selectedRow != '') {
        selectedRow.map((item, i) => { ids += item.id +','; });
      } 

      let searchAdv = false;
      if( this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_uploader.length > 0 ||
          (this.state.search_from_time && this.state.search_to_time)
          )
      {
        searchAdv = true;
      }
      let search_param = {
        order_ids: ids,
        search: this.state.search_text,
        adv_search: searchAdv,
        start_date: startDate,
        end_date: endDate,
      };
      if (this.state.is_search == true) {
      let param = {
        order_ids: ids,
        search: this.state.search_text,
        adv_search: searchAdv,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status || (apiUtil.customFilter(this.state.search_status_id, 'label', 'In Progress') && this.state.order_status == null ? this.state.acKnowLedgeId + ','+ this.state.search_status_id.map(obj => obj.value).join(',') : this.state.search_status_id.map(obj => obj.value).join(',')),
        do_number: !this.state.search_do_number ? this.state.search_do_number : this.state.search_do_number.trim(),
        worker_ids: !this.state.search_worker_id ? this.state.search_worker_id : this.state.search_worker_id.trim(),
        vendor_ids: !this.state.search_vendor_id ? this.state.search_vendor_id : this.state.search_vendor_id.trim(),
        order_number: !this.state.search_delivery_number ? this.state.search_delivery_number : this.state.search_delivery_number.trim(),
        time_slot: (this.state.search_from_time && this.state.search_to_time) ? (this.state.search_from_time + " - " + this.state.search_to_time) : '',
        delivery_type: !this.state.search_delivery_type ? this.state.search_delivery_type : this.state.search_delivery_type.trim(),
        recipient_name: !this.state.search_recipient_name ? this.state.search_recipient_name : this.state.search_recipient_name.trim(),
        recipient_contact: !this.state.search_recipient_contact ? this.state.search_recipient_contact : this.state.search_recipient_contact.trim(),
        recipient_email: !this.state.search_recipient_email ? this.state.search_recipient_email : this.state.search_recipient_email.trim(),
        item_description: !this.state.search_item_desc ? this.state.search_item_desc : this.state.search_item_desc.trim(),
        delivery_address: !this.state.search_delivery_address ? this.state.search_delivery_address : this.state.search_delivery_address.trim(),
        postal_code: !this.state.search_postal_code ? this.state.search_postal_code : this.state.search_postal_code.trim(),
        uploader: this.state.search_uploader.map(obj => obj.id).join(','),
        allocation_group: !this.state.search_group_id ? this.state.search_group_id : this.state.search_group_id.trim(),
      };
      
      if(this.state.order_status == null && apiUtil.customFilter(this.state.search_status_id, 'label', 'Assigned')) {
        param.order_status += ','+this.state.assignToVendorId;
      }
      
      search_param = param;
      search_param_global = param;
      } else {
        if (Object.keys(search_param_global).length !== 0){
          search_param = search_param_global;
          if(ids) {
            search_param.order_ids = ids;
          }
        }
      }

      ldsApi.exportXLS('admin/api/orders/delivery/export', search_param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}.xlsx`);
    
            this.setState({
              data: this.state.state_copy_data,
              state_copy_data: _.cloneDeep(this.state.state_copy_data),
              downloadIsLoading: false,
            });
            search_param_global = {}
            this.rowData = null;
          }, (error, type) => {
            if(result.status == 400) {
              apiUtil.toast('You can export maximum 10000 orders once.',  'warning', 'error');
            }
            this.setState({
              data: this.state.state_copy_data,
              state_copy_data: _.cloneDeep(this.state.state_copy_data),
              downloadIsLoading: false,
            });
            
          });
      });
    });
  }
  /* END EXPORT API */

  generatePDF = () => {
    const { accessToken } = this.context;
    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);
    
    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + 'multiple_generate_label.pdf?ids=' + ids,'_blank');    
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
    }
    
    this.rowData = null;
    this.state.state_copy_data.forEach(d => {if(d.tableData)d.tableData.checked = false});
    this.setState({
      data: this.state.state_copy_data
    })

  } 

  generatePOD = ( ) => {

    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);

    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?is_customer=true&ids=' + ids + '&is_customer=true' + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
      // window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?start_date=' + moment(this.state.startDate).format("YYYY-MM-DD") + "&end_date=" + moment(this.state.endDate).format("YYYY-MM-DD") + '&is_customer=true' + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
    }
    this.rowData = null;
    this.setState({
      data: this.state.state_copy_data,
      state_copy_data: _.cloneDeep(this.state.state_copy_data),
      downloadIsLoading: false,
    });
    // window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&is_customer=true' + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
  }

  handleChange = (val) => this.setState({value: val});

  searchForm = () => {
      const time_slot_default_value = [{label: '10am - 2pm',value: '10am-2pm'},{label: '2pm - 6pm',value: '2pm-6pm'},{label: '6pm - 9pm',value: '6pm-9pm'}];
      return <div>
      <Row className="pt-4 px-4 mar-1 d-flex flex--xs-column-reverse">
        <Col xs={12} md={6} className="m-0 p-0">
            <ToggleButtonGroup type="radio" id="toggle-border-radius" name="search-btn" value={this.state.value} onChange={this.handleChange}>
                <ToggleButton id="tbg-radio-1" value={1} className={this.state.value == 1 ? "text-dark border-0 px-3 py-3 border-end active-toggle-btn" : "text-dark border-0 px-3 py-3 border-end"}>
                    Search
                </ToggleButton>
                <ToggleButton id="tbg-radio-2" value={2} className={this.state.value == 2 ? "text-dark border-0 px-3 py-3 active-toggle-btn" : "text-dark border-0 px-3 py-3"}>
                    Advanced Search
                </ToggleButton>
            </ToggleButtonGroup>
            {(this.state.search_text || this.state.search_delivery_number || this.state.search_do_number || this.state.search_worker_id.length > 0 || this.state.search_time_slot || this.state.search_delivery_type || this.state.search_recipient_name || this.state.search_recipient_email || this.state.search_recipient_contact || this.state.search_item_desc || this.state.search_delivery_address || this.state.search_postal_code || this.state.search_group_id || this.state.search_status_id.length > 0 || this.state.search_from_time || this.state.search_to_time || this.state.search_uploader.length > 0) && <IconButton
            onClick={e => {
              this.setState({
                search_text: '',
                search_do_number: '',
                search_worker_id: '',
                search_delivery_number: "",
                search_time_slot: '',
                search_delivery_type: '',
                search_recipient_name: '',
                search_recipient_contact: '',
                search_recipient_email: '',
                search_item_desc: '',
                search_delivery_address: '',
                search_postal_code: '',
                search_group_id: '',
                search_status_id: [],
                order_status_list: this.state.old_order_status_list,
                search_from_time: '',
                search_to_time: '',
                search_uploader: [],
              }, () =>{
                this.callReadApi()
              })
              
            }}
            ><span  data-tooltip="clear search" data-tooltip-conf="danger"><img src={Filterimg} width="25px" /></span></IconButton> }
        </Col>     
    </Row>
    <Row className="px-4">
    <Col>
    {this.state.value == 1 ?
     <Card className="overflow-hidden rounded-0 border-0 d-flex max-xs-width-control-900 justify-content-between align-items-center w-100 flex-row">
     <div className="row border-0 d-flex justify-content-start align-items-center">
         <div className="col-xs-12 col-md-8 d-flex max-xs-width-control-100">
         <input placeholder="Enter Job Number, Company Name, etc" className="border-0 border-bottom p-2 m-4 max-height-input"
         value={this.state.search_text}
         onChange={e => {
           this.setState({
             search_text:e.target.value
           })
         }}
          />
         </div>
         <div className="col-xs-12 col-md-3 max-xs-width-control-100 left-margin my-4">
         <Button className="upload-style max-height-btn"
         onClick={e =>{this.pages  = 1; this.callReadApi()}}
         ><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
         </div>
        </div>
        <div className="two-btn-pos "><div className={'border-delete-btn'} 
              onClick={()=> { 
                this.setState({
                  openDialogMassDelete: true
                });
              }}><FontAwesomeIcon icon={faTrashAlt} /></div>
        <button 
        className="btn left-margin"
        onClick={e=>
          this.setState({
            openSettingsDialog: true
          })
        }
        >Table Columns</button>
         </div>
     
    </Card> 
          : 
          <Card className="overflow-hidden rounded-0 border-0 box-shadow-search">
              <Row className="px-5">
                  <Col xs={12} md={6} className="py-4">
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Tracker Number
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_delivery_number}
                          onChange={e=>{
                            this.setState({
                              search_delivery_number: e.target.value,
                              is_search: false,
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          D.O Number
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_do_number}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_do_number: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Order Status
                      </Col>
                      <Col xs={12} md={8}> 
                        <ReactSelect 
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              height: '150px', // Set the desired height for the dropdown box
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px', // Set the maximum height for the list of options
                              overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                            }),
                          }}
                          options={this.state.order_status_list}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.search_status_id}
                          onChange={(e,opt) => {
                            let order_status = []
                            if(opt.action == 'select-option') {
                              order_status = this.state.order_status_list.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.setState({
                                search_status_id: e,
                                order_status_list: order_status
                              });                          
                            } else if(opt.action == 'remove-value') {
                              order_status = this.state.old_order_status_list.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              
                              this.setState({
                                search_status_id: e,
                                order_status_list: order_status,                          
                              });
                            } else if(opt.action == 'clear') {
                              this.setState({
                                search_status_id: [],
                                order_status_list: this.state.old_order_status_list
                              });                          
                            }
                          }}
                        />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                      Job Type
                      </Col>
                      <Col xs={12} md={8}> 
                      <select class="form-select search-input-style" aria-label="Default select example"
                      value={this.state.search_delivery_type}
                      onChange={e=>{
                        this.setState({
                          is_search: false,
                          search_delivery_type: e.target.value
                        })
                      }}
                      >
                          <option value="">Select Job Type</option>
                          <option value={'Collection'}>Pick Up </option>
                          <option value={'Delivery'}>Delivery</option>
                      </select>
                      </Col>
                  </Row>
                  </Col>
                  <Col xs={12} md={6} className="py-4">
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Recipient's Name
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_recipient_name}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_recipient_name: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Contact No
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_recipient_contact}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_recipient_contact: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Uploader
                      </Col>
                      <Col xs={12} md={8}> 
                        <ReactSelect 
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              height: '150px', // Set the desired height for the dropdown box
                              backgroundColor: 'white', // Set a solid background color
                              zIndex: 1000, // Ensure it appears above other elements
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px', // Set the maximum height for the list of options
                              overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                              backgroundColor: 'white', // Set a solid background color
                              zIndex: 1000, // Ensure it appears above other elements
                            }),
                          }}
                          options={this.state.uploaders}
                          getOptionLabel={(option) => option.first_name+' '+option.last_name} // Use `name` as the label
                          getOptionValue={(option) => option.id}  // Use `id` as the value
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.search_uploader}
                          onChange={(e,opt) => {
                            let check_uploader = []
                            if(opt.action == 'select-option') {
                              check_uploader = this.state.uploaders.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.setState({
                                is_search: false,
                                search_uploader: e
                              })

                            } else if(opt.action == 'remove-value') {
                              check_uploader = this.state.oldUploaders.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                        
                              this.setState({
                                search_uploader: e,
                                uploaders: check_uploader,                          
                              },() => {
                                this.callReadApi();   
                              });
                            } else if(opt.action == 'clear') {
                              this.setState({
                                search_uploader: [],
                                uploaders: this.state.oldUploaders,
                              },() => {
                                this.callReadApi();   
                              });                          
                            }
                            
                          }}
                        />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Delivery Address
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_delivery_address}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_delivery_address: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Postal Code
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_postal_code}
                            onChange={e=>{
                              this.setState({
                                is_search: false,
                                search_postal_code: e.target.value
                              })
                            }}
                          />
                      </Col>
                  </Row>
                  </Col>
              </Row>
              <Row className="border-0 d-flex justify-content-center align-items-center pb-4">
                  <Button className="upload-style max-height-btn" 
                  onClick={e=>{this.pages  = 1; 
                    this.setState({
                      statusSelected: 0,
                      order_status: null,
                      is_search: true,
                    })
                    this.callReadApi()}}><FontAwesomeIcon icon={faMagnifyingGlass} 
                  /> Search</Button>
              </Row>
          </Card>
          }
    </Col>
    </Row>
  </div>
  }

  isCollectionFailed = (deliOrder) => {
    return deliOrder.failed_collection ? deliOrder.failed_collection : false;
  }
  
  callCreateColumnOrder = (sort) => {
    const { accessToken } = this.context;
    api.create('application_company_settings', {
      enabled: true,
      rule: "job_column_order",
      json_data: JSON.stringify(sort)
    }, accessToken)
      .then(result => {
        if (result.data.data.result.id) {
          localStorage.setItem("job_column_order", JSON.stringify(result.data.data.result));
          this.setState({
            sortColumn: this.state.sortColumn
          });
        }
    });   
  }
  
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('orders/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null, (data && data.sorted_order_details) ? data.sorted_order_details : []);
              }
            });
          }, (error, type) => {
            apiUtil.toast(error, 'warning', 'error'); 
            this.setState({
              isLoading: false,
            });
          });
      });
    });
  }
  
  arrMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
  
  onColumnDragged = (sourceIndex,destinationIndex) => {
 
    const originalColumn = this.getColumns(false).filter((obj) => obj.hidden == false);
    const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
    const columnOrder = this.state.sortColumn.length > 0 ? this.state.sortColumn : []
    let sortArray = columnOrder.length > 0 ? columnOrder : Array.from(Array(originalColumn.length).keys())
    const sortedArray = this.arrMove(sortArray,sourceIndex,destinationIndex);
    this.setState({
      sortColumn: sortedArray 
    });
    this.callCreateColumnOrder(sortedArray);   
  }
  
  /*
  *  Column field name and hidden name must same
  */
  
  getColumns = (isSort=true) => {
    let columns = [
      { 
        title: "Tracker Number",
        field: "delivery_number",
        customSort: (a,b) => (a.order_number? a.order_number: '').localeCompare(b.order_number? b.order_number : ''),
        hidden: this.state.hiddenColumns.includes('delivery_number'),
        render: (row, type) => {
          if(type === 'row'){
            return  (row && row.order_number && row.order_number !== '') ?  
            <span 
            className={(this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection")) ? "linkable-place" : ""}
            style={{display:'inline-block'}}  
            onClick={(e) => {
              e.stopPropagation();
              if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                this.setState({
                  openPreviewDialog: true,
                  openPreviewDialogItem: row.id,
                });
              }
            }}> {row.order_number} </span>: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Trip Date",
        field: "date",
        customSort: (a,b) => (a.drop_off_date? a.drop_off_date: '').localeCompare(b.drop_off_date? b.drop_off_date : ''),
        hidden: this.state.hiddenColumns.includes('date'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.drop_off_date && row.drop_off_date !== '') ? 
            <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{moment(row.drop_off_date).format(apiUtil.getImportDateFormat()) }</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "D.O Number", 
        field: "do_number",
        cellStyle: {
          maxWidth: 160,
       },
        sorting: false,
        customSort: (a,b) => (a.do_number? a.do_number: '').localeCompare(b.do_number? b.do_number : ''),
        hidden: this.state.hiddenColumns.includes('do_number'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.do_number && row.do_number !== '') ? <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Job Type", 
        field: "delivery_type",
        sorting: false,
        customSort: (a,b) => (a.job_type? a.job_type: '').localeCompare(b.job_type? b.job_type : ''),
        hidden: this.state.hiddenColumns.includes('delivery_type'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.job_type && row.job_type !== '') ? <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.job_type == "Collection" ? 'Pick Up' : 'Delivery'}</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Project", 
        field: "is_project",
        sorting: false,
        customSort: (a,b) => (a.is_project !== null? a.is_project.toString(): '').localeCompare(b.is_project !== null? b.is_project.toString() : ''),
        hidden: this.state.hiddenColumns.includes('is_project'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.is_project !== null) ? `${row.is_project == true ? "yes" : "No"}` : "No";
          } else {
            return row;
          }
        }
      },
      { 
        title: "Category", 
        field: "period_of_delivery",
        sorting: false,
        customSort: (a,b) => (a.period_of_delivery? a.period_of_delivery: '').localeCompare(b.period_of_delivery? b.period_of_delivery : ''),
        hidden: this.state.hiddenColumns.includes('period_of_delivery'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.period_of_delivery !== null) ? `${row.period_of_delivery}` : "";
          } else {
            return row;
          }
        }
      },
      { 
        title: "Pick Up Ref (D.O)",
        field: "collection_reference",
        sorting: false,
        customSort: (a,b) => (a.collection_reference? a.collection_reference: '').localeCompare(b.collection_reference? b.collection_reference : ''),
        hidden: this.state.hiddenColumns.includes('collection_reference'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.collection_reference && row.collection_reference !== '') ? <span style={{display:'inline-block'}}>{row.collection_reference}</span> : '';
          } else {
            return row;
          }
        }
      },
        { 
          title: "Ordered By", 
          field: "order_by",
          sorting: false,
          customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : ''),
          hidden: this.state.hiddenColumns.includes('order_by'),
          render: (row, type) => {
            if(type === 'row'){
              if(row.vendor_id && row.vendor_id == this.state.user.owner_info.application_company_id) {
                return (row && row.uploader && (row.uploader.application_company && row.uploader.application_company.company_name !== '')) ? <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.uploader.application_company.company_name}</span> : '';
              } else {
                return (row && row.uploader && (row.uploader.first_name !== '' || row.uploader.last_name !== '')) ? <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row.uploader.first_name? row.uploader.first_name: '') + ' ' + (row.uploader.last_name? row.uploader.last_name: '')}</span> : '';
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Order Status",
          field: "order_status",
          cellStyle: {
            minWidth: 280,
            maxWidth: 280,    
          },
          sorting: false,
          customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('order_status'),
          render: (row, type) => {
            let item = [];
            if(type === 'row'){
              if (row && row.order_status && row.order_status.status == "Completed") {
                item = this.state.tableStatus.filter((dd)=> dd.status == "Completed" || dd.status == "Failed") 
              } else if (row && row.order_status && row.order_status.status == "Failed") {
                item = this.state.tableStatus.filter((dd)=> dd.status == "Pending" || dd.status == "Cancelled" || dd.status == "Self Collect" || dd.status == "On Hold" || dd.status == "Completed") 
              } else if (row && row.order_status && row.order_status.status == "Acknowledged" || row.order_status && row.order_status.status == "In Progress") {
                item = this.state.tableStatus.filter((dd)=> dd.status == "Pending" || dd.status == "Cancelled" || dd.status == "Self Collect" ) 
              } else if (row && row.order_status && row.order_status.status != "Not Assigned") {
                item = this.state.tableStatus.filter((dd)=> dd.status == "Pending" || dd.status == "Cancelled" || dd.status == "Self Collect" || dd.status == "On Hold" ) 
              } else {
                item = this.state.tableStatus
              }
              return <Box>
                    <Box>
                    {this.state.currentRole != "Customer Template Role" && ((this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Status')) && (!row.vendor_id || row.vendor_id == this.state.user.owner_info.application_company_id) && (this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) ?
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold"+'   ' :  row.order_status.status == "Acknowledged" && this.state.currentRole == "Customer Template Role" ? "In Progress"+'   ' : row.order_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending"+'   ': row.order_status.status == "Assign Pending" ? "On Hold"+'   ' : row.order_status.status == "Acknowledged" && this.state.currentRole == "Customer Template Role"? "In Progress"+'   ' : row.order_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' :  row.order_status.status+'   ' : ''}
                      items={item}
                      onClick={(item) => {
                        if(row.drop_off_worker_id && row.drop_off_worker_id > 0 || item.text.toString().toLowerCase().trim() === 'cancelled' || item.text.toString().toLowerCase().trim() === 'pending'){
                          let data = {
                            data: [
                              {
                                id: [ row.id ],
                                job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                                  step.job_step_id = step.id;
                                  if (item.text.toString().toLowerCase().trim() === "completed") {
                                    step.job_step_status_id = 3;
                                  } else {
                                    step.job_step_status_id = 1;
                                  }
                                  return step
                                }): row.job_steps,
                                order_status_id: item.id,
                                send_notification_to_customer: true,
                              }
                            ]
                          };

                          if(item.text.toString().toLowerCase().trim() === 'pending'){
                            data.data[0]['drop_off_worker_id'] = null;
                            data.data[0]['extra_worker_ids'] = null;
                          }

                          if(item.text.toString().toLowerCase().trim() === 'assigned to vendor' && row.drop_off_worker_id) {
                            apiUtil.toast("Order is already In Progress", "check_circle")
                            return;
                          }

                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                              this.callReadApi();
                              // this.setState({
                              //   data: newData,
                              // });
                            }
                          });
                        } else {
                          apiUtil.toast('You must first assign a driver!', 'warning', 'error'); 
                        }
                      }}
                    /> : 
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold"+'   ': row.order_status.status == "Acknowledged" && this.state.currentRole == "Customer Template Role" ? "In Progress" : row.order_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending"+'   ': row.order_status.status == "Assign Pending" ? "On Hold"+'   ' : row.order_status.status == "Acknowledged" && this.state.currentRole == "Customer Template Role"? "In Progress"+'   '  : row.order_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : row.order_status.status+'   ' : ''}
                      // rightIcon={<ArrowDownIcon />}
                      // items={this.state.tableStatus}
                    />
                     }        
                  </Box>
              </Box>;
          } else {
              return row;
            }
          }
        },
        { 
          title: "POD", 
          field: "POD",
          cellStyle: {
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('POD'),
          render: (row, type) => {
            if(type === 'row'){
              let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
              if(jobStep) {
              let signature_img = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == true) : []
              let proof_img =  row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == false) : []
             
              return <div className='d-flex align-items-center justify-content-center'>
                      {proof_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                      <img src={proof_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                      <span>
                        <img src={proof_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                      </span> 
                      {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                        <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                            if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: row.id,
                              });
                            }
                          }
                        }}/> : ''} </div> : signature_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                          <img src={signature_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                        <span>
                          <img src={signature_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                        </span>
                        {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                          <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                              if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                                this.setState({
                                  openPreviewDialog: true,
                                  openPreviewDialogItem: row.id,
                                });
                              }
                            }
                          }}/> : ""}
                  </div> : '' }
              </div>
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Reason for Failure", 
          field: "reason_for_failure",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 && a.job_steps[0].order_attempts[0].reason? a.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 && b.job_steps[0].order_attempts[0].reason ? b.job_steps[0].order_attempts[0].reason : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('reason_for_failure'),
          render: (row, type) => {
            if(type === 'row'){
              let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
              if(jobStep) {
              let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
              return row && row.order_status && row.order_status.status == "Failed" ? failed_reason : '';
              } 
            } else {
              return row;
            }
          }
        },
        ... (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) ?[{ 
          title: "Delivery Address", 
          field: "delivery_address",
          cellStyle: {
            maxWidth: 180
          },
          sorting: false,
          customSort: (a,b) => (a.drop_off_address? a.drop_off_address: '').localeCompare(b.drop_off_address? b.drop_off_address : ''),
          hidden: this.state.hiddenColumns.includes('delivery_address'),
          render: (row, type) => {
            if(type === 'row'){
              // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
              return (row && row.drop_off_address && row.drop_off_address !== '') ?<span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_address }</span>  : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "ETA", 
          field: "arrival_time",
          cellStyle: {
            // minWidth: 100,
            maxWidth: 100
          },
          sorting: false,
          customSort: (a,b) => (a.route_entity && a.drop_off_latitude && a.drop_off_longitude ? a.route_entity.arrival: '').localeCompare(b.route_entity && b.drop_off_latitude && b.drop_off_longitude? b.route_entity.arrival : ''),
          hidden: this.state.hiddenColumns.includes('arrival_time'),
          render: (row, type) => {
            let today = moment().format('YYYY-MM-DD');
            let startDate = moment(row.drop_off_date).format('YYYY-MM-DD');
            if(type === 'row'){
              return (today == startDate && row && row.route_entity && row.route_entity.active && (row.order_status.status == "In Progress" || row.order_status.status == "Acknowledged")) && row.drop_off_latitude && row.drop_off_longitude ? moment.parseZone(row.route_entity.arrival).format('H:mm')  : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Postal Code", 
          field: "postal_code",
          sorting: false,
          customSort: (a,b) => Number(a.drop_off_postal_code ? a.drop_off_postal_code: '') - Number(b.drop_off_postal_code ? b.drop_off_postal_code: ''),
          hidden: this.state.hiddenColumns.includes('postal_code'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Between", 
          field: "time_slot",
          sorting: false,
          customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[0]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[0]: ''),
          hidden: this.state.hiddenColumns.includes('time_slot'),
          render: (row, type) => {
            if(type === 'row'){
              let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
              return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[0] : '';
            } else {
              return row;
            }
          }
        }, 
        { 
          title: "End", 
          field: "time_slot",
          sorting: false,
          customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[1]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[1]: ''),
          hidden: this.state.hiddenColumns.includes('time_slot'),
          render: (row, type) => {
            if(type === 'row'){
              let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
              return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[1] : '';
            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') ||
            this.state.currentRole == 'Customer Template Role' ||
            this.state.currentRole == 'Ops Admin' ||
            this.state.currentRole == 'Finance Admin' ||
            this.state.currentRole == 'BD Admin') ? [{
          title: "Driver-Partner", 
          field: "driver_partner",
          sorting: false,
          customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('driver_partner'),
          render: (row, type) => {
            if(type === 'row'){
              if(row.drop_off_worker_id && row.drop_off_worker_id > 0 && row.drop_off_worker){
                
                row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
              }
              if(row.vendor_id && row.vendor_id != null){
                row.vendorName = ( row.vendor && row.vendor.company_name != null ? row.vendor.company_name : "") ;
              }
              if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') ||
                  this.state.currentRole == 'Ops Admin') {
              return <Box>
                <CustomAutosuggest 
                  disabled = {(this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection")) && row.order_status && row.order_status.status == "Completed"  || row.order_status && row.order_status.status == "Failed"? true: false}
                  className={'no-margin z-index-autoSuggest'}
                  items={this.state.workerItems}
                  placeholder={'Assign Driver'}
                  value={row.vendor_id ? row.vendor_id != this.state.user.owner_info.application_company_id ? row.vendorName : row.workerName : row.workerName }
                  onChange={(value) => {
                    let status = '';
                    let data = '';
                    let orderStatusItem = '';
                    if (value) {
                      let check_driver = this.state.workerItems.filter(dd => dd.value == value.value && dd.label == value.label);
                      if(typeof(value.item.application_company_id) != 'undefined') {
  
                          row.workerName = value.label;
                          row.workerId = value.value;
                          row.workerItem = value.item;
      
                          status = 'assigned';
                        
                        // let orderStatusItem = apiUtil.customFilter(this.state.status, 'job_assign', status);}
                        orderStatusItem = apiUtil.customFilter(this.state.status, 'status', status);
                        data = {
                          data: [
                            {
                              id: [ row.id ],
                              job_steps: row.job_steps ? row.job_steps.map(item=> {
                                item.job_step_id = item.id
                                return item
                              }): row.job_steps,
                              order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                              send_notification_to_customer: true,
                              drop_off_worker_id: row.workerId,
                              extra_worker_ids: null,
                              vendor_id: row.vendor_id && row.vendor_id != this.state.user.owner_info.application_company_id ? null : row.vendor_id
                            }
                          ]
                        };
                      } else {
                        
                          row.vendorName = value.label;
                          row.vendorId = value.value;
                          row.vendorItem = value.item;

                          row.manpowerName = '';
                          row.manpowerId = null;
                          row.manpowerItem = null;

                        data = {
                          data: [
                            {
                              id: [ row.id ],
                              assign_type : "vendor",
                              vendor_id: row.vendorId,
                              extra_worker_ids: null,
                            }
                          ]
                        };
                      }
                      
                    } else {
                      row.workerName = '';
                      row.workerId = null;
                      row.workerItem = null;
  
                      status = null;

                      row.vendorName = '';
                      row.vendorId = row.vendor_id && row.vendor_id != this.state.user.owner_info.application_company_id ? null : row.vendor_id;
                      row.vendorItem = null;

                      if (row.vendorId == null) {
                        status = 'pending'
                      } else { 
                        status = 'assigned to vendor'}
                      orderStatusItem = apiUtil.customFilter(this.state.status, 'status', status);

                      data = {
                        data: [
                          {
                            id: [ row.id ],
                            job_steps: row.job_steps ? row.job_steps.map(item=> {
                              item.job_step_id = item.id
                              return item
                            }): row.job_steps,
                            order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                            send_notification_to_customer: true,
                            drop_off_worker_id: row.workerId,
                            extra_worker_ids: null,
                            vendor_id: row.vendorId,
                          }
                        ]
                      };
                    }
                    
                    if (row.application_company_id != this.state.user.owner_info.application_company_id) {
                      if (data.data[0]['vendor_id'] == this.state.user.owner_info.application_company_id || data.data[0]['vendor_id'] == null || data.data[0]['vendor_id'] == undefined)  {
                      this.callUpdateStatusApi(data, (newRow) => {
                        if(newRow){
                          let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                          this.setState({
                            data: newData,
                          });
                          this.callReadApi();
                        }
                      });
                      } else {
                        apiUtil.toast('Vendor to vendor is invalid option!!')
                        return
                      }
                    } else {
                        this.callUpdateStatusApi(data, (newRow) => {
                          if(newRow){
                            let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                            this.setState({
                              data: newData,
                            });
                            this.callReadApi();
                          }
                        });
                    }
                  }}
                  onSearch={(value, e) => {
                    // row.workerName = value;
                    // let searchItems = [];
                    // e.state.items.find( obj => {
                    //   let name = obj.label != null ? obj.label : '';
                    //   if(name.includes(value)) {
                    //     searchItems.push(obj)
                    //   }
                    // });
                    // searchItems = searchItems.length > 0 && value != '' ? searchItems : this.state.workerItems;
                    e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase())));
                  }}
                  onClick={(value, e) => {
                    e.showAllSuggestions();
                  }}
                />
              </Box>;
              } else {
              return row.vendor_id ? row.vendor_id != this.state.user.owner_info.application_company_id ? row.vendorName : row.workerName : row.workerName  
            }
            } else {
              return row;
            }
          }
        }] : [],
        // ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
        //     this.state.currentRole == 'Customer Template Role' ||
        //     this.state.currentRole == 'Ops Admin' ||
        //     this.state.currentRole == 'Finance Admin' ||
        //     this.state.currentRole == 'BD Admin') ? [{  
        //   title: "Driver-Assistant", 
        //   field: "extra_workers[0].first_name",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => (a.extra_workers && a.extra_workers.length > 0 && a.extra_workers[0].first_name ? a.extra_workers[0].first_name : '').toString().toLowerCase().trim().localeCompare((b.extra_workers && b.extra_workers.length > 0 && b.extra_workers[0].first_name ? b.extra_workers[0].first_name  : '').toString().toLowerCase().trim()),
        //   hidden: this.state.hiddenColumns.includes('driver_partner'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let label = '';
        //       let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

        //       if(manpowerName){
        //         label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
        //       }

        //       row.manpowerName = label;
        //       if ((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection")) && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
        //           this.state.currentRole == 'Ops Admin' ) {
        //       return <Box>
        //         <CustomAutosuggest 
        //           disabled={(row.drop_off_worker_id || row.vendor_id)? ((row.vendor_id != null && row.vendor_id != this.state.user.owner_info.application_company_id)? true : ((row.vendor_id != null && row.vendor_id == this.state.user.owner_info.application_company_id) && !row.drop_off_worker_id ? true : false)) : true}
        //           className={'no-margin'}
        //           placeholder={'Assign Manpower'}
        //           value={row.manpowerName}
        //           onChange={(value) => {
        //             if(value){
        //               row.manpowerName = value.label;
        //               row.manpowerId = value.value;
        //               row.manpowerItem = value.item;
        //             } else {
        //               row.manpowerName = '';
        //               row.manpowerId = null;
        //               row.manpowerItem = null;
        //             }

        //             if (row.job_steps === undefined) {
        //               row.job_steps = [];
        //             }

        //             let data = {
        //               data: [
        //                 {
        //                   id: [ row.id ],
        //                   job_steps: row.job_steps.length > 0 ? row.job_steps.map(item=> {
        //                     item.job_step_id = item.id
        //                     return item
        //                   }): row.job_steps,
        //                   order_status_id: row.order_status_id,
        //                   send_notification_to_customer: true,
        //                   extra_worker_ids: (row.manpowerId) ? row.manpowerId.toString() : null,
        //                 }
        //               ]
        //             };
        //             this.callUpdateStatusApi(data, (newRow) => {

        //               if(newRow){
        //                 let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
        //                 this.setState({
        //                   data: newData,
        //                 });
        //               }
        //             });
        //           }}
        //           onSearch={(value, e) => {
        //             row.manpowerName = value;
        //             this.callManpowerReadApi(value, (arr) => {
        //               e.showNewSuggestions(arr);
        //             });
        //           }}
        //           onClick={(value, e) => {
        //             this.callManpowerReadApi(value, (arr) => {
        //               e.showNewSuggestions(arr);
        //             });
        //           }}
        //         />
        //       </Box>;
        //       } else {
        //         return row.manpowerName;
        //       }
        //     } else {
        //       return row;
        //     }
        //   }
        // }] : [],
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Company')) ? [{  
          title: "Client Company", 
          field: "recipent_company",
          cellStyle: {
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('recipent_company'),
          sorting: false,
          customSort: (a,b) => (a.customer ? a.customer.company_name : '').toString().toLowerCase().trim().localeCompare((b.customer ? b.customer.company_name : '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if(type === 'row'){
              return <div style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.customer && row.customer.company_name !== '') ? HtmlParser(row.customer.company_name) : ''} </div>;
              // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Department", 
          field: "department",
          cellStyle: {
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => (a.department && a.department.name? a.department.name: '').localeCompare(b.department && b.department.name? b.department.name : ''),
          hidden: this.state.hiddenColumns.includes('department'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.department && row.department.name ) ? HtmlParser(row.department.name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Recipient's Name", 
          field: "recipient_name",
          cellStyle: {
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => (a.drop_off_contact_name? a.drop_off_contact_name: '').localeCompare(b.drop_off_contact_name ? b.drop_off_contact_name : ''),
          hidden: this.state.hiddenColumns.includes('recipient_name'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_contact_name ) ? HtmlParser(row.drop_off_contact_name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Company", 
          field: "company",
          cellStyle: {
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => (a.drop_off_name? a.drop_off_name: '').localeCompare(b.drop_off_name ? b.drop_off_name : ''),
          hidden: this.state.hiddenColumns.includes('company'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_name ) ? HtmlParser(row.drop_off_name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Contact No.", 
          field: "recipient_contact",
          sorting: false,
          customSort: (a,b) => (a.drop_off_contact_no? a.drop_off_contact_no: '').localeCompare(b.drop_off_contact_no ? b.drop_off_contact_no : ''),
          hidden: this.state.hiddenColumns.includes('recipient_contact'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_contact_no) ? HtmlParser(row.drop_off_contact_no) : ''} </span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Alternative Contact", 
          field: "alternate_contact",
          sorting: false,
          customSort: (a,b) => (a.drop_off_alternate_contact? a.drop_off_alternate_contact: '').localeCompare(b.drop_off_alternate_contact ? b.drop_off_alternate_contact : ''),
          hidden: this.state.hiddenColumns.includes('alternate_contact'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_alternate_contact) ? HtmlParser(row.drop_off_alternate_contact) : ''} </span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Recipient Email", 
          field: "recipient_email",
          cellStyle: {
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => (a.notification_email? a.notification_email: '').localeCompare(b.notification_email ? b.notification_email : ''),
          hidden: this.state.hiddenColumns.includes('recipient_email'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.notification_email ) ? HtmlParser(row.notification_email) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Description')) ?[{ 
          title: "Item Description", 
          field: "item_description",
          cellStyle: {
            maxWidth: 200
          },
          sorting: false,
          customSort: (a,b) => (a.drop_off_description? a.drop_off_description: '').localeCompare(b.drop_off_description ? b.drop_off_description : ''),
          hidden: this.state.hiddenColumns.includes('item_description'),
          render: (row, type) => {
            if(type === 'row'){
              return <span className='none-backgroud-color' style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : ''}</span>;
              // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "XS", 
          field: "xs_packages",
          sorting: false,
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('xs_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "S", 
          field: "s_packages",
          sorting: false,
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('s_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "M", 
          field: "m_packages",
          sorting: false,
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('m_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "L", 
          field: "l_packages",
          sorting: false,
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('l_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Oversized", 
          field: "oversized_packages",
          sorting: false,
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('oversized_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        // { 
        //   title: "Document", 
        //   field: "document",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   // hidden: this.state.hiddenColumns.includes('document'),
        //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).document && !isNaN(JSON.parse(a.extra_data).document) ? JSON.parse(a.extra_data).document : '') - Number(b.extra_data && JSON.parse(b.extra_data).document && !isNaN(JSON.parse(b.extra_data).document) ? JSON.parse(b.extra_data).document : ''),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.document) ? extra_data.document : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Boxes", 
        //   field: "boxes",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   // hidden: this.state.hiddenColumns.includes('boxes'),
        //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).boxes && !isNaN(JSON.parse(a.extra_data).boxes) ? JSON.parse(a.extra_data).boxes : '') - Number(b.extra_data && JSON.parse(b.extra_data).boxes && !isNaN(JSON.parse(b.extra_data).boxes) ? JSON.parse(b.extra_data).boxes : ''),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.boxes) ? extra_data.boxes : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Cartons", 
        //   field: "cartons",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   // hidden: this.state.hiddenColumns.includes('cartons'),
        //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).cartons && !isNaN(JSON.parse(a.extra_data).cartons) ? JSON.parse(a.extra_data).cartons : '') - Number(b.extra_data && JSON.parse(b.extra_data).cartons && !isNaN(JSON.parse(b.extra_data).cartons) ? JSON.parse(b.extra_data).cartons : ''),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.cartons) ? extra_data.cartons : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        { 
          title: "Pallets", 
          field: "pallets",
          sorting: false,
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('pallets'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Depalletization Request", 
          field: "depalletization_request",
          sorting: false,
          customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).depalletization_request ? JSON.parse(a.package_info).depalletization_request : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).depalletization_request ? JSON.parse(b.package_info).depalletization_request : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('depalletization_request'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.depalletization_request) ? package_info.depalletization_request  : '';
             } else {
              return row;
            }
          }
        },
        { 
          title: "Size in cm(L + W + H)", 
          field: "size_lwh",
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).size_l_w_h  && !isNaN(JSON.parse(a.package_info).size_l_w_h) ? JSON.parse(a.package_info).size_l_w_h : '') - Number(b.package_info && JSON.parse(b.package_info).size_l_w_h && !isNaN(JSON.parse(b.package_info).size_l_w_h)? JSON.parse(b.package_info).size_l_w_h : ''),
          hidden: this.state.hiddenColumns.includes('size_lwh'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Weight (KG)", 
          field: "weight",
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).weight  && !isNaN(JSON.parse(a.package_info).weight) ? JSON.parse(a.package_info).weight : '') - Number(b.package_info && JSON.parse(b.package_info).weight && !isNaN(JSON.parse(b.package_info).weight)? JSON.parse(b.package_info).weight : ''),
          hidden: this.state.hiddenColumns.includes('weight'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info &&  JSON.parse(row.package_info)
              return (package_info && package_info.weight) ? package_info.weight : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Waiting Time", 
          field: "waiting_time",
          sorting: false,
          customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).waiting_time ? JSON.parse(a.package_info).waiting_time : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).waiting_time ? JSON.parse(b.package_info).waiting_time : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('waiting_time'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.waiting_time) ? package_info.waiting_time : '';
             } else {
              return row;
            }
          }
        },
        ... ( this.state.currentRole != 'Customer Template Role') ? [{
          title: "Additional Manpower", 
          field: "no_of_movers",
          hidden: this.state.hiddenColumns.includes('no_of_movers'),
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
            } else {
              return row;
            }
          }
        }] :[
        { 
          title: "Additional Movers", 
          field: "no_of_movers",
          hidden: this.state.hiddenColumns.includes('no_of_movers'),
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
            } else {
              return row;
            }
          }
        }, 
        { 
          title: "Cancellation Fee", 
          field: "cancellation_fee",
          sorting: false,
          hidden: this.state.hiddenColumns.includes('cancellation_fee'),
          render: (row, type) => {
            if(type === 'row'){
              return '';
            } else {
              return row;
            }
          }
        }],
        { 
          title: "Price", 
          field: "price",
          cellStyle: {
            minWidth: 100,
            maxWidth: 100
          },
          sorting: false,
          customSort: (a,b) => Number(a.amount ? a.amount : '') - Number(b.amount ? b.amount : ''),
          hidden: this.state.hiddenColumns.includes('price'),
          render: (row, type) => {
            if(type === 'row'){
              if ( row.vendor_id == this.state.user.owner_info.application_company_id) {
                return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
              } 
              return (row && row.amount && row.amount !== '') ? parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
              
            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) || (this.state.currentRole == "BD Admin") || (this.state.currentRole == "Finance Admin")  ?[{ 
          title: "Driver Payout", 
          field: "driver_payout",
          hidden: this.state.hiddenColumns.includes('driver_payout'),
          sorting: false,
          customSort: (a,b) => Number(a.driver_payout ? a.driver_payout : '') - Number(b.driver_payout ? b.driver_payout : ''),
          render: (row, type) => {
            if(type === 'row'){
              if((row.vendor_id && row.vendor_id != this.state.user.owner_info.application_company_id) || !row.drop_off_worker_id ) {
                return '-'
              }
              return (row && row.driver_payout && row.driver_payout !== '') ? parseFloat(row.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Notes to Driver-Partner", 
          field: "notes_to_driver",
          cellStyle: {
            // minWidth: 180,
            maxWidth: 180
          },
          sorting: false,
          customSort: (a,b) => (a.driver_notes? a.driver_notes: '').localeCompare(b.driver_notes ? b.driver_notes : ''),
          hidden: this.state.hiddenColumns.includes('notes_to_driver'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_notes && row.driver_notes !== '') ? HtmlParser(row.driver_notes) : '';
             } else {
              return row;
            }
          }
        }
      ];
    if(isSort) {
      const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
      const columnOrder = this.state.sortColumn.length > 0 ? this.state.sortColumn : []
      if(columnOrder.length > 0) {
        let columnSort = [];
        columnOrder.map((val,index) => {
          columnSort.push(columns[val])     
        });
        return columnSort;
      }
    }
    return columns;         
  }
  
 reSaveColumnOrder = (hiddenColumns,isSave=true) => {
    let originalColumn = this.getColumns(false);
    let hiddenColumn = hiddenColumns.filter((obj) => obj != '');
    const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
    const columnOrder = this.state.sortColumn.length > 0 ? this.state.sortColumn : []
    let sortArray = columnOrder.length > 0 ? columnOrder : Array.from(Array(originalColumn.length).keys());  
   
    if(hiddenColumn.length > 0) {
      let indexList = [];
      let removeIndex = '';
      let newArray = [];
      originalColumn.map((obj,index) => {
        if(obj.hidden) {
          removeIndex = sortArray.findIndex((val) => val == index);
          if(removeIndex > -1) {
            sortArray.splice(removeIndex,1);
          }
        } else if (!sortArray.includes(index)) {
          sortArray.splice(index, 0, index);         
        }
      });         
    } else {
      originalColumn.map((obj,index) => {
        if(!sortArray.includes(index)) {
          sortArray.splice(index, 0, index);   
        }
      });             
    }
    this.setState({
      sortColumn: sortArray
    });
    if(isSave) {
      this.callCreateColumnOrder(sortArray);   
    }
  }

    /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'} className="py-4">
      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                  text={<div style={{ width: 90, height: 90 }}>
                    <CircularProgressbar value={this.state.status_total == 0? 1 : item.total} maxValue={this.state.status_total == 0? 1 : this.state.status_total} strokeWidth={14} text={`${item.total}`} 
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      // rotation: 0.25,
                  
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',

                      // Text size
                      textSize: '24px',
                  
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                  
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',
                  
                      // Colors
                      pathColor: `${
                        item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                        item.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                      textColor:  `${
                        item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                        item.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                      trailColor: '#c3c3c3',
                      backgroundColor: '#AE0264',
                    })}/>
                  </div>}
                  count={<span className={
                    'circle-progress-label ' + item.status.replaceAll(' ', '-').trim().toLowerCase()}>{item.status}</span>}
                />
              })}
              onClick={(index) => {
                this.pages = 1;
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }
                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].status ==  'In Progress' ?  this.state.status[index].id+','+this.state.acKnowLedgeId : this.state.status[index].id : null,
                  current_status_name: (index && index > 0) ? this.state.status[index].status : null,
                  defaultGroupOrder: defaultGroupOrder,
                  total_current_status_job: (index && index > 0) ? this.state.status[index].total : 0,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */

  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;
    if(!this.state.isLoading) {

      return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      isDraggable={false}
      grouping={false}
      limit={this.limits}
      page={this.pages}
      total={this.state.total}
      data={this.state.data ? fromJS(this.state.data).toJS() : this.state.data}
      defaultExpanded={true}
      isCollectionFailed={this.isCollectionFailed}
      onColumnDragged={this.onColumnDragged}
      selection={true}
      handleRowClick={(e, row)=> {
        e.stopPropagation();
        
        if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
          this.setState({
            openPreviewDialog: true,
            openPreviewDialogItem: row.id,
          });
        }
        
      }}
      onPageChange={(page,limit) => {
          // this.setState({
          //   limit:limit,
          //   page:page
          // }, () => {
            this.pages  = page;
            this.limits =  limit;
            this.callReadApi();
          // });
      }}
      onSelectionChange={(rows, column)=>{
        const selectedData = rows.slice();
        this.rowData = selectedData;
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={this.getColumns()}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

  columnSettingsDialog = () => {
    const { accessToken } = this.context;
    let hiddenColumns = this.state.hiddenColumns;
    return <CustomDialog2
      title={'Column Settings'}
      btnOkText={'Save Settings'}
      open={this.state.openSettingsDialog}
      hideCancelButtons={true}
      onOk={() => {
        this.setState({
          isLoading: true,
          hiddenColumns: hiddenColumns
        });

        if (this.state.hiddenColumnSettingId > 0) {
          api.update('application_company_settings/' + this.state.hiddenColumnSettingId, {
            rule: 'job_column_settings',
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
                this.reSaveColumnOrder(hiddenColumns);
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        } else {
          api.create('application_company_settings', {
            enabled: true,
            rule: "job_column_settings",
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
                this.reSaveColumnOrder(hiddenColumns);
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        }
      }}
      onClose={() => {
        this.setState({
          openSettingsDialog: false
        })
      }}
    >
     <CustomCheckbox checked={this.state.hiddenColumns.includes('date')} label={'Hide Trip Date'} onChange={(checked) => this.toggleColumn(checked, ['date'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_number')} label={'Hide Tracker Number'} onChange={(checked) => this.toggleColumn(checked, ['delivery_number'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('do_number')} label={'Hide D.O Number'} onChange={(checked) => this.toggleColumn(checked, ['do_number'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_type')} label={'Hide Job Type'} onChange={(checked) => this.toggleColumn(checked, ['delivery_type'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_by')} label={'Hide Ordered By'} onChange={(checked) => this.toggleColumn(checked, ['order_by'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_status')} label={'Hide Order Status'} onChange={(checked) => this.toggleColumn(checked, ['order_status'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('POD')} label={'Hide POD'} onChange={(checked) => this.toggleColumn(checked, ['POD'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('reason_for_failure')} label={'Hide Reason For Failure'} onChange={(checked) => this.toggleColumn(checked, ['reason_for_failure'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_address')} label={'Hide Delivery Address'} onChange={(checked) => this.toggleColumn(checked, ['delivery_address'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('arrival_time')} label={'Hide ETA'} onChange={(checked) => this.toggleColumn(checked, ['arrival_time'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('postal_code')} label={'Hide Postal Code'} onChange={(checked) => this.toggleColumn(checked, ['postal_code'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('time_slot')} label={'Hide Time Window'} onChange={(checked) => this.toggleColumn(checked, ['time_slot'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('is_project')} label={'Hide Project'} onChange={(checked) => this.toggleColumn(checked, ['is_project'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('period_of_delivery')} label={'Hide Category'} onChange={(checked) => this.toggleColumn(checked, ['period_of_delivery'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('collection_reference')} label={'Hide Pick Up Ref (D.O)'} onChange={(checked) => this.toggleColumn(checked, ['collection_reference'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('company')} label={'Hide Company'} onChange={(checked) => this.toggleColumn(checked, ['company'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('department')} label={'Hide Department'} onChange={(checked) => this.toggleColumn(checked, ['department'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('alternate_contact')} label={'Hide Alternative Contact'} onChange={(checked) => this.toggleColumn(checked, ['alternate_contact'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_partner')} label={'Hide Driver Partner'} onChange={(checked) => this.toggleColumn(checked, ['driver_partner'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipent_company')} label={'Hide Client Company'} onChange={(checked) => this.toggleColumn(checked, ['recipent_company'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipient_name')} label={"Hide Recipient's Name"} onChange={(checked) => this.toggleColumn(checked, ['recipient_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipient_contact')} label={'Hide Contact No.'} onChange={(checked) => this.toggleColumn(checked, ['recipient_contact'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipient_email')} label={'Hide Recipient Email'} onChange={(checked) => this.toggleColumn(checked, ['recipient_email'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('item_description')} label={'Hide Item Description'} onChange={(checked) => this.toggleColumn(checked, ['item_description'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('xs_packages')} label={'Hide XS Packages'} onChange={(checked) => this.toggleColumn(checked, ['xs_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('s_packages')} label={'Hide S Packages'} onChange={(checked) => this.toggleColumn(checked, ['s_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('m_packages')} label={'Hide M Packages'} onChange={(checked) => this.toggleColumn(checked, ['m_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('l_packages')} label={'Hide L Packages'} onChange={(checked) => this.toggleColumn(checked, ['l_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('oversized_packages')} label={'Hide Oversized Packages'} onChange={(checked) => this.toggleColumn(checked, ['oversized_packages'])} />
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('document')} label={'Hide Document'} onChange={(checked) => this.toggleColumn(checked, ['document'])} /> */}
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('boxes')} label={'Hide Boxes'} onChange={(checked) => this.toggleColumn(checked, ['boxes'])} /> */}
     <CustomCheckbox checked={this.state.hiddenColumns.includes('pallets')} label={'Hide Pallets'} onChange={(checked) => this.toggleColumn(checked, ['pallets'])} />
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('cartons')} label={'Hide Cartons'} onChange={(checked) => this.toggleColumn(checked, ['cartons'])} /> */}
     <CustomCheckbox checked={this.state.hiddenColumns.includes('size_lwh')} label={'Hide Size in cm(L + W + H)'} onChange={(checked) => this.toggleColumn(checked, ['size_lwh'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('weight')} label={'Hide Weight'} onChange={(checked) => this.toggleColumn(checked, ['weight'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('waiting_time')} label={'Hide Waiting Time'} onChange={(checked) => this.toggleColumn(checked, ['waiting_time'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('no_of_movers')} label={'Hide Additional Manpower'} onChange={(checked) => this.toggleColumn(checked, ['no_of_movers'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('cancellation_fee')} label={'Hide Cancellation Fee'} onChange={(checked) => this.toggleColumn(checked, ['cancellation_fee'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('price')} label={'Hide Price'} onChange={(checked) => this.toggleColumn(checked, ['price'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('notes_to_driver')} label={'Hide Notes to Driver-Partner'} onChange={(checked) => this.toggleColumn(checked, ['notes_to_driver'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('depalletization_request')} label={'Hide Depalletization Request'} onChange={(checked) => this.toggleColumn(checked, ['depalletization_request'])} />
     

    </CustomDialog2>
  }

  toggleColumn = (checked, columns) => {
    let hiddenColumns = this.state.hiddenColumns;

    if (columns.length > 0) {
      if (checked === true) { // hide column
        hiddenColumns = hiddenColumns.concat(columns);
      } else { // show column
        columns.forEach(column => {
          let index = hiddenColumns.indexOf(column);
          if (index !== -1) {
            hiddenColumns.splice(index, 1);
          }
        });
      }
    }
    this.reSaveColumnOrder(hiddenColumns,false);
    this.setState({
      hiddenColumns: hiddenColumns
    });
  }


  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog2 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      // hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
      btnOkText={"Save Changes"}
      hideCancelButtons={true}
      hideOkButtons={true}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        onSave={() => {
          this.callInitApis();
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });       
        }}
    /> 
    </CustomDialog2>
    } else {
      return  <CustomDialog2 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      // hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
      hideCancelButtons={true}
      hideOkButtons={true}
    >
    <h1>User has no permissions for details</h1>
    </CustomDialog2>
    }
  }
  /* END DIALOG */

  render() {
    return <Container fluid>
        <Row className="p-4">
           <Col xs={12} md={12} className="d-flex justify-content-end align-items-center py-1 max-xs-width-control-100">
            <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                // localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));
                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, ()=> {
                  this.callInitApis();
                });
              }}
            />
            <Button className="upload-style"
                onClick={() => {
                  this.setState({
                  downloadMenu:true
                });}}
        ><FontAwesomeIcon icon={faDownload} />
        </Button>
          <Menu
            id="download-menu-job"
            anchorEl={this.state.downloadMenu}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(this.state.downloadMenu)}
            onClose={() => {
                this.setState({
                  downloadMenu: null
                });
            }}
          >
            <MenuItem onClick={() => {this.callExportXLSApi(); }} > Export CSV </MenuItem>
            <MenuItem onClick={() => { this.generatePDF()}} > Generate Label </MenuItem>
            <MenuItem  onClick={() => { this.generatePOD() }} > Generate POD </MenuItem>
          </Menu>
        </Col>
      </Row>
       <Row className="p-4 overflow-hidden">
        <Col xs={12}>
        {<Card className="cd-shadow">
          {this.state.isLoadingStatus ?  
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className="p-3">
            <CircularProgress size={35} />
          </Box>
          : this.customStatus()}
        </Card> }
        </Col>
      </Row>

        {this.searchForm()}
        <Row className="p-4">
          <Col>
            {this.customTable()}
          </Col>
        </Row>
        {this.previewDialog()}
        {this.massDeleteDialog()}
        {this.columnSettingsDialog()}
        <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>
    </Container>;
  }
}

export default OrderHistory;
